import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';

import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import Block from '../../adapters/helpers/Block';
import { IArticleOverview } from '../../types';
import { AdditionalReadingBlockConstants, ComponentFactoryConstants} from '../../adapters/helpers/Constants'
import Heading from '../Heading/Heading'

const IAdditionalReadingProps = {
    contentType: PropTypes.oneOf([AdditionalReadingBlockConstants.additionalReadingBlock]),
    className: PropTypes.string,
    classNames: PropTypes.string, // contentful misspelling
    name: PropTypes.string,
    title: PropTypes.string,
    anchorId: PropTypes.string,
    isLegacyLayout: PropTypes.bool,
    articles: PropTypes.arrayOf(IArticleOverview),
};

export default function AdditionalReadingBlock(props) {
    const { extraAttributes } = props;

    const block = new Block(props);
    const fields = block.getFields();

    return (
        <AdditionalReading
            {...fields}
            anchorId={block.getAnchorId()}
            articles={extraAttributes?.entity?.relatedArticles}
        />
    );
}

AdditionalReadingBlock.propTypes = {
    document: PropTypes.shape({
        fields: PropTypes.exact(IAdditionalReadingProps),
    }),
    extraAttributes: PropTypes.shape({
        entity: PropTypes.shape({
            relatedArticles: PropTypes.arrayOf(IArticleOverview),
        }),
    }),
};

export function AdditionalReading(props) {
    const { classNames, className = classNames } = props;
    const { title, anchorId, articles, isLegacyLayout} = props;

    if (!articles || articles.length === 0) {
        return null;
    }

    const variationB = (articles.length <= 2);
    articles.map((article) => {
        if (article.fields?.slug == null) {
            console.info('SLUG IS NULL FOR ', article.fields.name);
        }
    });
    return (
        <div className={`ob-additional-reading ${isLegacyLayout ? 'ob-additional-reading__legacy' : ''} ${variationB ? 'ob-additional-reading--wide' : ''} ${className || ''}`} id={anchorId}>
            <Heading className="ob-additional-reading__heading">
                {title}
            </Heading>

            <div className="ob-additional-reading__articles">
                <ul className="ob-additional-reading__list">
                    {articles.map((article, i) => {
                        const contentThumbnail = article.fields?.thumbnailImage?.fields?.asset?.fields?.file?.url;                                     
                        if (article.fields?.slug != null) {
                            return (
                                <li className="ob-additional-reading__item" key={i}>
                                    <a className="ob-additional-reading__item-link" href={article.fields?.slug?.fields?.slug}>
                                        <div className="ob-additional-reading__item-image">
                                            {!!contentThumbnail && (
                                                <LazyLoad offset={100} placeholder={
                                                    <picture className="ob-additional-reading__item-pic">
                                                        <img
                                                            className="ob-additional-reading__item-img"
                                                            alt={`${article.fields?.title} ${AdditionalReadingBlockConstants.articleLink}`} 
                                                            height={150}
                                                        />
                                                    </picture>
                                                }>
                                                    <picture className="ob-additional-reading__item-pic">
                                                        {variationB && (
                                                            <source media={ComponentFactoryConstants.mediaUpper} srcSet={ImageFactory.imageContentfullDpr(contentThumbnail, ComponentFactoryConstants.dprUpper)} />
                                                        )}

                                                        <source media={ComponentFactoryConstants.mediaLower} srcSet={ImageFactory.imageContentfullDpr(contentThumbnail, ComponentFactoryConstants.dprMedium)} />
                                                        <source srcSet={ImageFactory.imageContentfullDpr(contentThumbnail, ComponentFactoryConstants.dprAuto)} />

                                                        <img
                                                            className="ob-additional-reading__item-img"
                                                             alt={`${article.fields?.title} ${AdditionalReadingBlockConstants.articleLink}`} 
                                                            src={ImageFactory.imageContentfullSrc(contentThumbnail, ComponentFactoryConstants.dprUpper)}
                                                            srcSet={ImageFactory.imageContentfullDpr(contentThumbnail, ComponentFactoryConstants.dprUpper)}
                                                            height={150}
                                                        />
                                                    </picture>
                                                </LazyLoad>
                                            )}
                                        </div>

                                        <div className="ob-additional-reading__item-info">
                                            <div className="ob-additional-reading__item-title">
                                                {article.fields?.title}
                                            </div>

                                            {!!article.fields?.readTime && (
                                                <div className="ob-additional-reading__item-time">
                                                    {article.fields?.readTime} min read
                                                </div>
                                            )}
                                        </div>
                                    </a>
                                </li>
                            );
                        } else {
                            return '';
                        }
                    })}
                </ul>
            </div>
        </div>
    );
}

AdditionalReading.propTypes = IAdditionalReadingProps;
